import { DateTime } from 'luxon';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { DatePicker } from '@/pages/Schedule/components/DatePicker';
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  forwardRef,
  type MenuButtonProps,
} from '@/ui';
import { getStartOfWeek } from '@/utils/dates';
import classes from './styles.module.scss';

const CalendarInput = forwardRef(({ startDate, endDate, ...props }, ref) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();

  return (
    <Button
      as={MenuButton}
      minW={{ base: 'auto', sm: '185px' }}
      ref={ref}
      variant="secondary"
      {...props}
    >
      {isMobileBreakpoint ? (
        startDate.toFormat('MMMM yyyy')
      ) : (
        <>
          {startDate.toFormat('MMM d')} &ndash;{' '}
          {endDate.minus({ days: 1 }).toFormat('MMM d, yyyy')}
        </>
      )}
    </Button>
  );
});

type Props = Omit<MenuButtonProps, 'onChange'> & {
  startDate: DateTime;
  endDate: DateTime;
  onChange: (date: DateTime) => void;
};

export const WeekPicker = ({
  startDate,
  endDate,
  onChange,
  ...props
}: Props) => {
  return (
    <Menu
      flip={false} // https://linear.app/agendhero/issue/AH-1256/date-picker-enhancement-top-align-the-calendar
      isLazy
    >
      {({ onClose }) => (
        <>
          <CalendarInput endDate={endDate} startDate={startDate} {...props} />

          <Portal>
            <MenuList pb="0" pt="0">
              <DatePicker
                className={classes.weekpicker}
                inline
                selected={startDate}
                showWeekPicker
                onChange={(date) => {
                  onClose();

                  if (date) {
                    onChange(getStartOfWeek(DateTime.fromJSDate(date)));
                  }
                }}
              />
            </MenuList>
          </Portal>
        </>
      )}
    </Menu>
  );
};
